import { useUserContext } from '@/context/UserContext';

export default function UserLoginAlert() {
  const { userLoginAlert, setUserLoginAlert } = useUserContext();
  return (
    <div
      className={`fixed bottom-10 left-10 z-10 flex items-center gap-4 rounded-xl border-2 border-green-500 bg-green-500/75 px-4 py-2 ${
        userLoginAlert ? 'opacity-100' : 'opacity-0'
      } transition-opacity`}
    >
      <div className="text-xl font-semibold">Inloggad!</div>
      <button onClick={() => setUserLoginAlert(false)} className="text-xl">
        &#x2715;
      </button>
    </div>
  );
}
