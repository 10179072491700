import { BASE_URL } from '@/modules/strapiApiClient';
import { StrapiImageData } from '@/modules/strapiTypes';
import Image, { ImageProps } from 'next/image';

interface StrapiImageProps {
  strapiImage: StrapiImageData;
}

type Props = StrapiImageProps & Partial<ImageProps>;

interface ExtraImageProps {
  width?: number;
  height?: number;
}

export function StrapiImage({ strapiImage, ...imageProps }: Props) {
  if (strapiImage.data?.attributes === undefined) {
    return null;
  }
  const { attributes } = strapiImage.data;
  const extraProps: ExtraImageProps = {};
  if (!imageProps.fill) {
    extraProps.width = attributes.width;
    extraProps.height = attributes.height;
  }
  return (
    <Image
      {...imageProps}
      src={`${!attributes.url?.startsWith('http') ? BASE_URL : ''}${
        attributes.url ?? ''
      }`}
      alt={attributes.alternativeText ?? ''}
      {...extraProps}
    />
  );
}
