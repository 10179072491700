'use client';

import Link from 'next/link';
import Image from 'next/image';
import ShoppingCartIcon from '@/icons/shopping_cart_white.svg';
import ListIcon from '@/icons/list.svg';
import UserIcon from '@/icons/user.svg';
import LoggedInUserIcon from '@/icons/logged_in_user.svg';
import SearchIcon from '@/icons/search_icon.svg';
import DropdownArrowIcon from '@/icons/dropdown-menu-arrow-black.svg';
import { StrapiLinks, StrapiMainMenu } from '@/modules/strapiTypes';
import { StrapiImage } from './StrapiImage';
import { useEffect, useRef, useState } from 'react';
import { SearchModal } from '@/components/sectionNavbar/partials/SearchModal';
import { usePathname, useRouter } from 'next/navigation';
import { useShoppingCart } from '@/context/shoppingCartContext';
import { useUserContext } from '@/context/UserContext';
import UserLoginAlert from '@/components/sectionNavbar/partials/UserLoginAlert';
import { HamburgerModalView } from './HamburgerModalView';
import { ModalVisibility, NavbarModal } from './NavbarModal/NavbarModal';
import { useNavbarModal } from '@/context/NavbarModalsContext';

type MainMenu = NonNullable<StrapiMainMenu>;

interface NavbarProps {
  strapiData: MainMenu;
  transparent?: boolean;
  children?: React.ReactNode;
  adminLinks?: StrapiLinks[];
}

export function Navbar({
  strapiData,
  transparent = true,
  children,
  adminLinks,
}: Readonly<NavbarProps>) {
  const { attributes } = strapiData;
  const highlightedPath = usePathname();
  const { noItemsInCart } = useShoppingCart();
  const { userInfo } = useUserContext();
  const router = useRouter();

  const [height, setHeight] = useState(96);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setComponentHeight = () => {
      if (ref.current) {
        setHeight(ref.current.clientHeight);
      }
    };

    setComponentHeight();

    window.addEventListener('resize', setComponentHeight);

    return () => {
      window.removeEventListener('resize', setComponentHeight);
    };
  }, []);

  const [searchModalVisible, setSearchModalVisible] = useState(false);

  const [hamburgerMenuVisible, setHamburgerModalVisible] = useState(false);

  const { navbarModalVisible, setNavbarModalVisible } = useNavbarModal();

  const handleUserIconClick = () => {
    if (userInfo) {
      router.push('/profil');
    } else {
      setNavbarModalVisible(ModalVisibility.Login);
    }
  };

  return (
    <>
      <div
        className={transparent ? 'bg-hlr-gunmetal brightness-200' : 'bg-white'}
        style={{ height: height }}
      />
      <div
        ref={ref}
        className={`fixed top-0 z-10 w-full backdrop-blur-sm ${
          transparent ? 'bg-black/50' : 'bg-hlr-gunmetal'
        }`}
      >
        {!!attributes?.show_red_alert && !!attributes.red_alert && (
          <RedAlert
            props={attributes.red_alert}
            textColor={attributes.text_color_red_alert as string}
            bgColor={attributes.bg_color_red_alert as string}
          />
        )}
        <div className="mx-auto flex max-w-page-width-mx-80 cursor-pointer items-center justify-between gap-4 px-default-sm py-4 font-raleway md:px-default">
          <div className="shrink-0 md:hidden">
            <Image
              src={ListIcon as string}
              alt="listIcon"
              height={35}
              width={35}
              onClick={() => setHamburgerModalVisible(true)}
            />
          </div>
          {attributes?.logo && (
            <Link href="/" className="my-auto h-auto">
              <StrapiImage
                className="h-10 w-full md:h-16"
                strapiImage={attributes.logo}
              />
            </Link>
          )}
          <div
            className={`mx-auto hidden w-full justify-between gap-4 md:flex ${
              adminLinks ? 'max-w-5xl' : 'max-w-3xl'
            }`}
          >
            {(adminLinks || (attributes?.links ?? [])).map(
              ({ links }, index) => (
                <NavItem
                  links={links}
                  highlightedPath={highlightedPath ?? ''}
                  key={index}
                  transparent={transparent}
                />
              )
            )}
          </div>
          <div className="flex w-full max-w-navbarIconsWidth justify-between gap-1.5">
            <div className="relative">
              <div className="my-auto h-6 w-6 shrink-0 cursor-pointer transition ease-in-out hover:-translate-y-1 hover:scale-110">
                <Image
                  className="h-6 w-6"
                  src={SearchIcon as string}
                  alt="sök"
                  width={100}
                  height={100}
                  onClick={() => setSearchModalVisible((prev) => !prev)}
                />
              </div>
              {searchModalVisible && (
                <>
                  <div
                    className="fixed left-0 top-0 h-[100vh] w-[99vw]"
                    onClick={() => setSearchModalVisible(false)}
                  />
                  <SearchModal
                    onSearchItemClick={() => setSearchModalVisible(false)}
                  />
                </>
              )}
            </div>
            <Image
              className="relative my-auto h-6 w-6 shrink-0 cursor-pointer transition ease-in-out hover:-translate-y-1 hover:scale-110"
              src={(userInfo ? LoggedInUserIcon : UserIcon) as string}
              alt="content"
              width={100}
              height={100}
              onClick={() => handleUserIconClick()}
            />
            <div
              className={`relative order-2 my-auto shrink-0 cursor-pointer transition-transform ease-in-out hover:-translate-y-1 hover:scale-110 md:order-3 md:ml-0 md:w-max`}
            >
              <Image
                className="h-6 w-6"
                src={ShoppingCartIcon as string}
                alt="content"
                width={100}
                height={100}
                onClick={() => setNavbarModalVisible(ModalVisibility.Cart)}
              />
              <span
                className={`absolute -top-1 left-4 h-min w-min rounded-full px-1 text-center text-xs text-white ${
                  noItemsInCart ? 'bg-custom-red' : ' bg-gray-600'
                }`}
              >
                {noItemsInCart}
              </span>
            </div>
          </div>
        </div>
        {children}
      </div>
      <div
        className={`${
          hamburgerMenuVisible ? 'pointer-events-auto' : 'pointer-events-none'
        }`}
      >
        <HamburgerModalView
          highlightedPath={highlightedPath ?? ''}
          navItems={adminLinks || (attributes?.links ?? [])}
          displayHamburgerMenu={hamburgerMenuVisible}
          setDisplayHamburgerMenu={setHamburgerModalVisible}
        />
      </div>
      <NavbarModal
        visibility={navbarModalVisible}
        setVisibility={setNavbarModalVisible}
      />
      <UserLoginAlert />
    </>
  );
}

interface RedAlertProps {
  props: NonNullable<MainMenu['attributes']>['red_alert'];
  textColor: string | undefined;
  bgColor: string | undefined;
}

function RedAlert({ props, textColor, bgColor }: RedAlertProps) {
  if (!props?.display_name) {
    return null;
  }

  return (
    <div
      className={bgColor ? '' : 'bg-red-600'}
      style={{ backgroundColor: bgColor }}
    >
      <div className="mx-auto flex max-w-page-width-mx-80 items-center px-default-sm py-2 md:px-default">
        <Link
          href={props.url ?? ''}
          className={`${
            props.url ? 'pointer-events-auto' : 'pointer-events-none'
          } mx-auto text-center text-xs font-semibold text-white hover:underline`}
          style={{ color: textColor }}
          rel={props.rel}
        >
          {props.display_name}
        </Link>
      </div>
    </div>
  );
}

interface NavItemProps extends StrapiLinks {
  highlightedPath: string;
  transparent?: boolean;
}

function NavItem({ links, highlightedPath, transparent }: NavItemProps) {
  if (!links || links.length == 0) {
    return undefined;
  } else if (links.length == 1) {
    const link = links[0];
    return (
      <NavLink
        key={link.display_name}
        displayName={link.display_name}
        highlighted={highlightedPath === link.url}
        url={link.url}
        openInNewTab={link.open_in_new_tab}
        rel={link.rel}
      />
    );
  }

  const highlighted = links.some((l) => l.url === highlightedPath);
  const [link, ...sublinks] = links;

  return (
    <span className="group">
      <Link
        href={link.url ?? ''}
        target={link.open_in_new_tab ? 'blank' : undefined}
        rel={link.rel}
        className={
          'text-white whitespace-nowrap uppercase text-sm md:text-base hover:underline underline-offset-4 ' +
          (highlighted ? 'font-bold' : 'transition ease-in-out')
        }
      >
        {link.display_name}
        <Image
          className="inline invert"
          src={DropdownArrowIcon as string}
          alt="Dropdown Arrow Icon"
        />
      </Link>
      <ol
        className={`fixed -ml-4 hidden ${
          transparent ? 'bg-black/50' : 'bg-hlr-gunmetal'
        } p-2 group-hover:block`}
      >
        {sublinks.map((l) => (
          <li key={l.id} className="mt-1 px-2">
            <NavLink
              displayName={l.display_name}
              highlighted={highlightedPath === l.url}
              url={l.url}
              openInNewTab={l.open_in_new_tab}
              rel={l.rel}
            />
          </li>
        ))}
      </ol>
    </span>
  );
}

interface NavLinkProps {
  displayName?: string;
  url?: string;
  rel?: string;
  openInNewTab?: boolean;
  highlighted: boolean;
}

function NavLink({
  highlighted,
  displayName,
  url,
  rel,
  openInNewTab,
}: NavLinkProps) {
  return (
    <Link
      className={
        'text-white whitespace-nowrap uppercase text-sm md:text-base hover:underline underline-offset-4 ' +
        (highlighted ? 'font-bold' : 'transition ease-in-out')
      }
      href={url ?? ''}
      target={openInNewTab ? 'blank' : undefined}
      rel={rel}
    >
      {displayName}
    </Link>
  );
}
