import Link from 'next/link';
import React, { Dispatch, SetStateAction } from 'react';
import Image from 'next/image';
import HlrLogoBlack from '@/icons/hlr-logo-black.svg';
import { CSSTransition } from 'react-transition-group';
import { StrapiLinks } from '@/modules/strapiTypes';

interface HamburgerModalViewProps {
  navItems: StrapiLinks[];
  displayHamburgerMenu: boolean;
  setDisplayHamburgerMenu: Dispatch<SetStateAction<boolean>>;
  highlightedPath: string;
}

export function HamburgerModalView({
  navItems,
  displayHamburgerMenu,
  setDisplayHamburgerMenu,
  highlightedPath,
}: HamburgerModalViewProps) {
  return (
    <>
      <div
        className={`fixed top-0 z-50 h-full w-full bg-black transition-opacity duration-300 ${
          displayHamburgerMenu ? 'opacity-50' : 'pointer-events-none opacity-0'
        }`}
        onClick={() => setDisplayHamburgerMenu(false)}
      />
      {/* @ts-expect-error Invalid return type for CSSTransition */}
      <CSSTransition
        in={displayHamburgerMenu}
        timeout={300}
        unmountOnExit
        classNames={{
          enter: '-translate-x-full',
          enterActive:
            'translate-x-0 transition-transformation duration-300 ease-in-out',
          exit: 'translate-x-0',
          exitActive:
            '-translate-x-100% transition-transformation duration-300 ease-in-out',
        }}
      >
        <div className="fixed top-0 z-50 h-full w-4/5 space-y-6 overflow-auto bg-white p-10">
          <div className="flex justify-between gap-4 pb-4">
            <Link
              href="/"
              className="relative h-10 w-32"
              onClick={() => setDisplayHamburgerMenu(false)}
            >
              <Image fill src={HlrLogoBlack as string} alt="hlrLogoBlack" />
            </Link>
            <button
              onClick={() => setDisplayHamburgerMenu(false)}
              className="h-fit text-4xl leading-none"
            >
              &#x2715;
            </button>
          </div>
          {navItems?.map(
            (links, index) =>
              !!links && (
                <NavItem
                  key={links.id ?? index}
                  links={links.links}
                  onClick={() => setDisplayHamburgerMenu(false)}
                  highlightedPath={highlightedPath}
                />
              )
          )}
        </div>
      </CSSTransition>
    </>
  );
}

interface NavItemProps extends StrapiLinks {
  onClick: () => void;
  highlightedPath: string;
}

function NavItem({ links, onClick, highlightedPath }: NavItemProps) {
  if (!links || links?.length === 0) {
    return undefined;
  } else if (links.length === 1) {
    const link = links[0];
    return (
      <Link
        key={link.id}
        onClick={onClick}
        className={
          'uppercase text-lg block ' +
          (link.url === highlightedPath
            ? 'font-bold underline decoration-dotted underline-offset-4'
            : 'transition ease-in-out hover:underline underline-offset-4')
        }
        href={link.url ?? ''}
      >
        {link.display_name}
      </Link>
    );
  }

  const [link, ...sublinks] = links;

  return (
    <span className="block text-lg uppercase">
      <Link
        key={link.id}
        onClick={onClick}
        className={
          'uppercase text-lg block ' +
          (link.url === highlightedPath
            ? 'font-bold underline decoration-dotted underline-offset-4'
            : 'transition ease-in-out hover:underline underline-offset-4')
        }
        href={link.url ?? ''}
      >
        {link.display_name}
      </Link>
      <ol>
        {sublinks.map((link, index) => (
          <li key={link.id ?? index}>
            <Link
              className={
                'ml-2 whitespace-nowrap uppercase text-sm md:text-base hover:underline underline-offset-4 ' +
                (link.url === highlightedPath
                  ? 'font-bold'
                  : 'transition ease-in-out')
              }
              href={link.url ?? ''}
              target={link.open_in_new_tab ? 'blank' : undefined}
              rel={link.rel}
            >
              {link.display_name}
            </Link>
          </li>
        ))}
      </ol>
    </span>
  );
}
