import { searchStore, searchStoreAsCustomer } from '@/modules/apiClient';
import { Image, SearchResult } from '@/modules/apiTypes';
import NextImage from 'next/image';
import Link from 'next/link';
import {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TextField } from '../../general/input/TextField';
import { useUserContext } from '@/context/UserContext';
import SearchIcon from '@/icons/search_icon_black.svg';
import Spinner from '@/icons/spinner.svg';
import { ProductPrice } from '../../pageStore/ProductPrice';
import { useShoppingCart } from '@/context/shoppingCartContext';
import { sendEcommerceEvent } from '@/modules/googleAnalytics';
import { categoryUrl, productUrl } from '@/modules/helper';

interface SearchModalProps {
  onSearchItemClick: () => void;
}

const MIN_SEARCH_LENGTH = 3;

export function SearchModal({
  onSearchItemClick,
}: SearchModalProps): ReactElement | null {
  const [searchResult, setSearchResult] = useState<SearchResult>();
  const [searchString, setSearchString] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const { authToken, selectedCustomer } = useUserContext();
  const { displayVat } = useShoppingCart();
  const callbackDebounceTimeoutId = useRef<ReturnType<typeof setTimeout>>();

  const search = useCallback(
    (searchString: string) => {
      if (callbackDebounceTimeoutId.current !== undefined) {
        clearTimeout(callbackDebounceTimeoutId.current);
      }
      callbackDebounceTimeoutId.current = setTimeout(() => {
        void (async () => {
          setIsSearching(true);
          if (authToken !== '' && selectedCustomer !== undefined) {
            const result = await searchStoreAsCustomer(
              searchString,
              selectedCustomer.id,
              authToken
            );
            setSearchResult(result);
          } else {
            const result = await searchStore(searchString);
            setSearchResult(result);
          }
          setIsSearching(false);
        })();
      }, 200);
    },
    [selectedCustomer, authToken]
  );

  useEffect(() => {
    if (searchString.length >= MIN_SEARCH_LENGTH) {
      search(searchString);
    }
  }, [searchString, search]);

  function sendSearchAnalytics(searchTerm: string) {
    sendEcommerceEvent({
      name: 'search',
      search_term: searchTerm,
    });
  }

  return (
    <div className="fixed inset-x-0 mx-auto w-[90vw] max-w-[1000px] border bg-white p-2 shadow md:absolute md:left-auto md:right-0 md:w-[50vw]">
      <div className="mb-2 flex items-center gap-2">
        <TextField
          name="search"
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
          extraClassNames="focus:border-hlr-form-border focus:ring-0"
          placeholder="Sök bland våra 400 produkter"
          noError
          autoFocus
        />
        <div className="relative">
          {isSearching && (
            <NextImage
              className="h-6 w-6 animate-spin"
              src={Spinner as string}
              alt="sök"
              width={100}
              height={100}
            />
          )}
          {!isSearching && (
            <NextImage
              className="h-6 w-6"
              src={SearchIcon as string}
              alt="sök"
              width={100}
              height={100}
            />
          )}
        </div>
      </div>
      <div className="flex max-h-[50vh] flex-col gap-2 overflow-y-scroll">
        {searchResult?.products.map((product) => (
          <SearchResultItem
            key={product.id}
            image={product.images[0]}
            name={product.name}
            onClick={() => {
              onSearchItemClick();
              sendSearchAnalytics(searchString);
            }}
            link={productUrl(product)}
          >
            <ProductPrice product={product} displayVat={displayVat} />
          </SearchResultItem>
        ))}
        {searchResult?.categories.map((category) => (
          <SearchResultItem
            key={category.id}
            image={category.image}
            name={category.name}
            onClick={() => {
              onSearchItemClick();
              sendSearchAnalytics(searchString);
            }}
            link={categoryUrl(category)}
          />
        ))}
      </div>
    </div>
  );
}

interface SearchResultItemProps {
  image?: Image | null;
  name: string;
  link: string;
  onClick?: () => void;
}

function SearchResultItem({
  image,
  name,
  link,
  onClick,
  children,
}: PropsWithChildren<SearchResultItemProps>): ReactElement {
  return (
    <Link className="group flex flex-row" href={link} onClick={onClick}>
      <div className="relative h-20 w-20 shrink-0 transition-opacity group-hover:opacity-70 md:w-28">
        <NextImage
          className="rounded border object-cover"
          src={image?.url ?? '/images/logo.png'}
          alt={image?.alt ?? 'logo'}
          fill
        />
      </div>
      <div className="h-min px-6 transition-opacity group-hover:opacity-70 hover:underline md:py-2">
        <p className="font-raleway font-semibold md:text-lg">{name}</p>
        {children}
      </div>
    </Link>
  );
}
